import React from 'react'
import styled, { css } from 'styled-components'
import { brandError } from '../../../assets/consts/color'

const Container = styled.div`
  text-align: left;
  & input,
  & textarea {
    //padding-left: 24px;
    //padding-right: 24px;
  }

  ${({ error }) =>
    error &&
    css`
      & input,
      & textarea,
      & .phone-field-wrapper {
        border-bottom-color: ${brandError};
        margin-bottom: 4px;
      }

      & .field-error-message {
        font-family: 'Poppins';
        font-size: 16px;
        color: ${brandError};
      }
    `}
`

function ValidationWrapper({ errorMessage, children }) {
  return (
    <Container error={!!errorMessage}>
      {children}
      {errorMessage && <span className="field-error-message">{errorMessage}</span>}
    </Container>
  )
}

export default ValidationWrapper
