require('./src/assets/css/main.scss')
const React = require('react')
const ReactDOM = require('react-dom/client')
const cookies = require('js-cookie')
const { default: SkeletonElement } = require('./src/components/organisms/Skeleton/Skeleton')
const { AppWrappedProvider } = require('./src/context/AppWrappedContext')
const {
  default: TrustedReviewsBlock,
} = require('./src/components/organisms/TrustedReviews/TrustedReviewsBlock')

const DefaultLayout = require('./src/components/pages/Layout').default
const GlobalNavigation = require('./src/components/organisms/NewNavigation/NewNavigation').default
const GlobalFooter = require('./src/components/organisms/GlobalFooter/NewFooter/NewFooter').default
const RootElement = require('./src/components/root-element').default

const {
  default: RecommendationBlock,
} = require("./src/components/organisms/RecommendationBlock/RecommendationBlock");
const GoogleRatingBadge = React.lazy(
  () => import('./src/components/organisms/GoogleRatingBadge/GoogleRatingBadge')
)
const CookieBanner = React.lazy(
  () => import('./src/components/organisms/CookieBanner/CookieBanner')
)

exports.wrapPageElement = ({ element, props }) => (
  <AppWrappedProvider>
    <GlobalNavigation pageContext={props.pageContext} />
    <DefaultLayout>{element}</DefaultLayout>

    <React.Suspense fallback={<SkeletonElement placeholder="Loading cookie block..." />}>
      <CookieBanner />
    </React.Suspense>
    {/* <React.Suspense fallback={<SkeletonElement placeholder="Loading google rating block..." />}> */}
    <GoogleRatingBadge />
    {/* </React.Suspense> */}
    <React.Suspense fallback={<SkeletonElement placeholder="Loading trusted reviews block..." />}>
      <TrustedReviewsBlock />
    </React.Suspense>
    <GlobalFooter />
    <RecommendationBlock />
  </AppWrappedProvider>
)

exports.replaceHydrateFunction = () => (element, container) => {
  const root = ReactDOM.createRoot(container)
  root.render(element)
}

exports.onClientEntry = () => {
  const consent = cookies.get('heliguy_tracking_consent')
  window.dataLayer = window.dataLayer || []
  if (typeof window.gtag === 'undefined') {
    window.gtag = function gtag() {
      dataLayer.push(arguments)
    }
  }

  window.consentChange = (consent) => {
    console.log(consent)

    if (consent) {
      window.gtag('consent', 'update', {
        ad_storage: consent?.consents?.ad_storage ? 'granted' : 'denied',
        analytics_storage: consent?.consents?.analytics_storage ? 'granted' : 'denied',
        ad_user_data: consent?.consents?.ad_user_data ? 'granted' : 'denied',
        ad_personalization: consent?.consents?.ad_personalization ? 'granted' : 'denied',
        personalization_storage: consent?.consents?.personalization_storage ? 'granted' : 'denied',
        functionality_storage: consent?.consents?.functionality_storage ? 'granted' : 'denied',
        security_storage: consent?.consents?.security_storage ? 'granted' : 'denied',
      })
    } else {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        personalization_storage: 'denied',
        functionality_storage: 'denied',
        security_storage: 'granted',
      })
    }
  }

  try {
    const parsedConsent = consent ? JSON.parse(consent) : null
    window.consentChange(parsedConsent)
  } catch (e) {
    console.error(e)
  }
}

exports.wrapRootElement = ({ element }) => <RootElement>{element}</RootElement>

exports.onInitialClientRender = () => {
  // Move Klaviyo script here to ensure it loads after initial render
  const scriptKlaviyo = document.createElement('script')
  scriptKlaviyo.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=U7wVZm'
  scriptKlaviyo.async = true
  document.body.appendChild(scriptKlaviyo)

  // Move Google badge script here to ensure it loads after initial render
  const scriptGoogleBadge = document.createElement('script')
  scriptGoogleBadge.src = 'https://apis.google.com/js/platform.js?onload=renderBadge'
  scriptGoogleBadge.async = true
  scriptGoogleBadge.defer = true
  document.body.appendChild(scriptGoogleBadge)
}

exports.onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  // const pagePath = location ? location.pathname + location.search + location.hash : undefined
  // setTimeout(() => {
  //   if (typeof window !== 'undefined' && window.dataLayer) {
  //     window.dataLayer.push({ event: 'page_view', page_path: pagePath })
  //   }
  // }, 100)
}


exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const {pathname, search} = routerProps.location

  const shouldScrollUp = !(search.includes('selectedTab') && pathname === prevRouterProps?.location.pathname);

  setTimeout(() => {
    shouldScrollUp && window.scrollTo(0, 0);
  }, 0)

  return false;
}
