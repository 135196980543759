import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import isomorphicFetch from 'isomorphic-fetch'
import { searchQuery } from './searchQuery'
import { predictiveSearchQuery } from './predictiveSearchQuery'
import {
  createGatsbyImageData,
  createGatsbyImageDataPlaceholder,
} from '../../helpers/createGatsbyImageData'
import { searchTitlesQuery } from './searchTitlesQuery'
import { predictiveSearchTitlesQuery } from './predictiveSearchTitlesQuery'

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: '2024-04',
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  customFetchApi: isomorphicFetch,
})

const search = async (query, first = 250) => {
  try {
    const response = await client.request(searchQuery, {
      variables: {
        query,
        first,
      },
    })

    return response.data.search
  } catch (error) {
    console.error(`Error search: ${error}`)
  }
}

const searchTitles = async (query, first = 250) => {
  try {
    const response = await client.request(searchTitlesQuery, {
      variables: {
        query,
        first,
      },
    })

    return response.data.search
  } catch (error) {
    console.error(`Error search titles: ${error}`)
  }
}

const predictiveSearch = async (query) => {
  try {
    const response = await client.request(predictiveSearchQuery, {
      variables: {
        query,
      },
    })

    return response.data.predictiveSearch
  } catch (error) {
    console.error(`Error search: ${error}`)
  }
}

const predictiveSearchTitles = async (query, first = 250) => {
  try {
    const response = await client.request(predictiveSearchTitlesQuery, {
      variables: {
        query,
        first,
      },
    })

    return response.data.predictiveSearch
  } catch (error) {
    console.error(`Error search: ${error}`)
  }
}

const contentfulSearch = async (query, limit = 50) => {
  try {
    const response = await fetch(
      `https://cdn.contentful.com/spaces/et769tc4wc1v/environments/${process.env.CONTENTFUL_BRANCH ?? 'development'}/entries?access_token=ZGkLEpTUXImt2sV6Ti1OoTx596X5Oafre3NRrtGR9sw&query=${query}&include=1&limit=${limit}`
    )
    const data = await response.json()

    const items = data.items.map((item) => {
      const posterImageId =
        item.fields.posterImage?.sys?.id || item.fields.mastheadBackgroundImage?.sys.id
      const posterImage = data.includes.Asset.find((asset) => asset.sys.id === posterImageId)

      return {
        ...item,
        fields: {
          ...item.fields,
          posterImage: posterImage
            ? createGatsbyImageData(posterImage)
            : createGatsbyImageDataPlaceholder(),
        },
      }
    })

    return items
  } catch (error) {
    console.error(`Error search: ${error}`)
  }
}

export const searchClient = {
  search,
  searchTitles,
  predictiveSearch,
  predictiveSearchTitles,
  contentfulSearch,
}
