import * as yup from 'yup'
import validatePhoneNumber from './validatePhoneNumber'

export const enquireFormSchema = ({ isClientQueryNotRequired }) =>
  yup.object({
    firstName: yup.string().required('First name is required').max(60, 'Max length 60 characters'),
    lastName: yup.string().max(60, 'Max length 60 characters'),
    phone: yup
      .string()
      .test('phone-validation', 'Phone is not valid', (phone) => validatePhoneNumber({ phone }))
      .max(30, 'Max length 30 characters'),
    email: yup
      .string()
      .email('Email is not valid')
      .required('Email is required')
      .max(100, 'Max length 100 characters'),
    clientQuery: yup
      .string()
      .when([], {
        is: () => isClientQueryNotRequired,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('Query is required'),
      })

      .max(500, 'Max length 500 characters'),
  })

export const contactCartSchema = yup.object({
  firstName: yup.string().required('First name is required').max(60, 'Max length 60 characters'),
  lastName: yup.string().required('Last name is required').max(60, 'Max length 60 characters'),
  phone: yup
    .string()
    .test('phone-validation', 'Phone is not valid', (phone) => validatePhoneNumber({ phone }))
    .max(30, 'Max length 30 characters'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required')
    .max(100, 'Max length 100 characters'),
  clientQuery: yup.string().required('Query is required').max(500, 'Max length 500 characters'),
})

export const contactFormSchema = yup.object({
  name: yup.string().trim().max(100, 'Max length 100 characters').required('Name is required'),
  email: yup
    .string()
    .email('Contact email is not valid')
    .max(100, 'Max length 100 characters')
    .required('Contact email is required'),
  phone: yup
    .string()
    .test('phone-validation', 'Phone is not valid', (phone) => validatePhoneNumber({ phone }))
    .max(30, 'Max length 30 characters'),
  clientMessage: yup
    .string()
    .trim()
    .required('Message is required')
    .max(500, 'Max length 500 characters'),
  pageHref: yup.string().trim().url().required('Page Href is required'),
})

export const contactFormFooterSchema = yup.object({
  email: yup
  .string()
  .email('Contact email is not valid')
  .max(100, 'Max length 100 characters')
  .required('Contact email is required'),
})

export const newLaunchProductFormSchema = yup.object({
  firstName: yup.string().max(60, 'Max length 60 characters'),
  lastName: yup.string().max(60, 'Max length 60 characters'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required')
    .max(100, 'Max length 100 characters'),
})

export const addressSchema = yup.object({
  // addressNumber: yup.string().required('House name or number is required'),
  address1: yup.string().required('Address line one is required'),
  address2: yup.string(),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country or State is required'),
  zip: yup.string().required('Postcode is required'),
})

export const loginSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .max(32, 'Password must be at most 32 characters long')
    .required('Password is required'),
})

export const registerSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Surname is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(32, 'Password must be at most 32 characters long')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
})

export const forgotPasswordSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
})

export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(32, 'Password must be at most 32 characters long')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
})

export const rentalSchema = yup.object({
  startDate: yup.string().required('Start date is required'),
  finishDate: yup.string().required('Finish date is required'),
  weeksCount: yup.number().required('Weeks count is required'),
  // flyerId: yup.string().when("isDrone", {
  //   is: "true",
  //   then: (schema) => schema.required("Flyer ID is required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
  flyerId: yup.string().notRequired(),
  operatorId: yup.string().when("isDrone", {
    is: "true",
    then: (schema) => schema.required("Operator ID is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  proofOfInsurance: yup
    .mixed()
    .test('fileRequired', 'Proof of insurance is required', (value) => value && value?.name?.length)
    .test(
      'fileSize',
      'Proof of insurance size must be less than or equal to 2MB',
      (value) => value && value.size <= 2 * 1024 ** 2
    ),
  // DON'T REMOVE
  // proofOfTraining: yup.mixed()
  //   .test('fileRequired', 'Proof of training is required', (value) => value && value?.name?.length)
  //   .test('fileSize', 'Proof of training size must be less than or equal to 2MB', (value) => value && value.size <= 2 * 1024 ** 2),
  smallPrintConfirm: yup.boolean().isTrue('Please, confirm rental deposit'),
})

export const notifySchema = yup.object({
  checkbox: yup.boolean(),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required')
    .max(100, 'Max length 100 characters'),
})

export const reviewSchema = yup.object({
  name: yup.string().trim().required('Name is required').max(100, 'Max length 100 characters'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required')
    .max(100, 'Max length 100 characters'),
  title: yup.string().trim().required('Title is required').max(100, 'Max length 100 characters'),
  body: yup.string().trim().required('Review is required').max(500, 'Max length 500 characters'),
  rating: yup.number().min(1, 'Rating is required'),
})

export const jobApplicationSchema = yup.object({
  firstName: yup.string().required('First name is required').max(60, 'Max length 60 characters'),
  lastName: yup.string().required('Last name is required').max(60, 'Max length 60 characters'),
  phone: yup
    .string()
    .required('Phone is required')
    .test('phone-validation', 'Phone is not valid', (phone) =>
      validatePhoneNumber({ phone, required: true })
    )
    .max(30, 'Max length 30 characters'),
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required')
    .max(100, 'Max length 100 characters'),
  candidateMesssage: yup
    .string()
    .required('Message is required')
    .max(500, 'Max length 500 characters'),
  attachment: yup
    .mixed()
    .test('fileRequired', 'Attachment is required', (value) => value && value?.name?.length)
    .test(
      'fileSize',
      'Attachment size must be less than or equal to 2MB',
      (value) => value && value.size <= 2 * 1024 ** 2
    )
    .test(
      'fileType',
      'Invalid file format. Only doc, docx, pdf, or txt files are allowed',
      (value) =>
        value &&
        [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'text/plain',
        ].includes(value.type)
    ),
})
