import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ArrowIconBlack from '../../../assets/images/icon_arrowBlack.png'

function SampleNextArrow(props) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

function SamplePrevArrow(props) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

const NextArrow = styled(SampleNextArrow)`
  &.slick-next {
    width: 24px;
    height: 24px;

    &:before {
      display: block;
      background-image: url(${ArrowIconBlack});
      background-position: left;
      background-size: 100%;
      background-repeat: no-repeat;
      content: '';
      height: 24px;
      width: 24px;
    }
  }
`

const PrevArrow = styled(SamplePrevArrow)`
  display: none;
`

function MobileSubMenu({ setSliderRef, children }) {
  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: true,
  }

  let sliderRef = useRef(null)

  useEffect(() => {
    if (setSliderRef) setSliderRef(sliderRef)
  }, [sliderRef])

  return (
    <Slider
      ref={(slider) => {
        sliderRef = slider
      }}
      {...settings}
    >
      {children}
    </Slider>
  )
}

export default MobileSubMenu
