// Module Import
//---------------------------------
import React, { useState, useCallback, useRef, useMemo, useEffect, useContext } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Link } from 'gatsby-link'
import useDetectScroll from '@smakss/react-scroll-direction'
import { AppContext } from '../../../context/app-context'
import './NewNavigation.scss'
import useStore from '../../../context/StoreContext'
import { useToast } from '../../../context/ToastContext'
import { PARENT_PAGE_MAP, ROUTES } from '../../../constants/routes'
import { searchClient } from '../../../utils/search/search'
import useClickOutside from '../../../hooks/useClickOutside'
import ArrowIcon from '../../../assets/images/icon_arrowOrange.png'
import { getSearchLink } from '../../../helpers/getSearchLink'
import { formatPhoneNumber } from '../../../helpers/formatPhoneNumber'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { useAppWrapped } from '../../../context/AppWrappedContext'
import BlackFridayBanner from '../BlackFridayBanner/BlackFridayBanner'
import MobileSubMenu from '../GalleryCarousel/MobileSubMenu'
import collections from '../../../constants/collections'
import MegaMenu from './MegaMenu/MegaMenu'
import CheveronIcon from '../../atoms/icons/CheveronIcon'
import AltCherevonIcon from '../../atoms/icons/AltCheveronIcon'

// Component Import
//---------------------------------

// Mobile Navigation Styles
//---------------------------------

// Debounce Function
//---------------------------------
function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

// Component Export
//---------------------------------
function GlobalNavigation({ pageContext }) {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPageSubLevel(sort: { createdAt: ASC }) {
        edges {
          node {
            pageSlug
            parentCategory
            navigationTitle
          }
        }
      }
      allContentfulPageRichSubPage(sort: { createdAt: ASC }) {
        edges {
          node {
            pageSlug
            parentCategory
            navigationTitle
          }
        }
      }
      contentfulComponentConfigData {
        contactsPhone
        topBanner
        topBannerText
        topBannerCtaText
        topBannerCtaLink
      }
      contentfulPageDronesTopLevel {
        collectionsList {
          title
          collectionName
          linkDestination
        }
      }
      allContentfulPageCollections(filter: { collectionFamily: { eq: "Enterprise" } }) {
        nodes {
          collectionsList {
            title
            collectionName
            linkDestination
          }
        }
      }
    }
  `)

  // Detect Scrolling & set status
  const { scrollPosition } = useDetectScroll()
  let hasScrolled
  if (scrollPosition.top > 1) {
    hasScrolled = true
  } else {
    hasScrolled = false
  }

  const currentPage = pageContext?.breadcrumb?.location.split('/').slice(0, 2).join('/')

  const isMegaMenu = [`${ROUTES.DRONES}`, `${ROUTES.TRAINING_TOP_LEVEL}`].includes(currentPage)

  const isSubMenu = [
    `${ROUTES.SURVEY_TOP_LEVEL}`,
    `${ROUTES.RENTAL}`,
    `${ROUTES.TRAINING_TOP_LEVEL}`,
    `${ROUTES.REPAIRS}`,
    `${ROUTES.DRONES}`,
  ].includes(currentPage)

  const [sliderRef, setSliderRef] = useState(null)
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false)

  const handleMegaMenuTrigger = () => setIsMegaMenuOpen(!isMegaMenuOpen)

  const handleMenuItemClick = (menuHandler) => {
    if (!isMegaMenu && sliderRef?.slickGoTo) {
      sliderRef.slickGoTo(0)
    }
    if (isMegaMenu) setIsMegaMenuOpen(false)
    menuHandler()
  }

  // Filter Navigation Links
  const surveyPages = data.allContentfulPageRichSubPage.edges.filter(
    (el) => el.node.parentCategory === PARENT_PAGE_MAP.SURVEY
  )
  const surveySubPages = data.allContentfulPageSubLevel.edges.filter(
    (el) => el.node.parentCategory === PARENT_PAGE_MAP.SURVEY
  )
  const repairPages = data.allContentfulPageSubLevel.edges.filter(
    (el) => el.node.parentCategory === PARENT_PAGE_MAP.REPAIRS
  )
  const rentalPages = [
    ...data.allContentfulPageSubLevel.edges.filter(
      (el) => el.node.parentCategory === PARENT_PAGE_MAP.RENTAL
    ),
    ...data.contentfulPageDronesTopLevel.collectionsList.filter(({ linkDestination }) =>
      ['rental-collection'].includes(linkDestination)
    ),
  ]
  const trainingPages = [
    ...data.allContentfulPageSubLevel.edges.filter(
      (el) => el.node.parentCategory === PARENT_PAGE_MAP.TRAINING
    ),
    ...data.allContentfulPageRichSubPage.edges.filter(
      (el) => el.node.parentCategory === PARENT_PAGE_MAP.TRAINING
    ),
  ]

  const enterprisePages =
    data.allContentfulPageCollections.nodes?.[0]?.collectionsList.filter((el) =>
      [
        collections.djIDock,
        collections.djIM30,
        collections.djiM300_M350,
        collections.djiMavicEnterprise,
        collections.djiMatrice4,
      ].includes(el.collectionName)
    ) || []

  const dronesPages = [
    {
      title: 'All Drones',
      linkDestination: ROUTES.DRONES,
    },
    ...data.contentfulPageDronesTopLevel.collectionsList.filter((el) =>
      [
        collections.consumerDrones,
        collections.enterpriseDrones,
        collections.proDrones,
        collections.payloads,
        collections.software,
      ].includes(el.collectionName)
    ),
    ...enterprisePages,
  ]

  const phone = data?.contentfulComponentConfigData?.contactsPhone || ''
  const isTopBanner = data?.contentfulComponentConfigData?.topBanner
  const topBannerText = data?.contentfulComponentConfigData?.topBannerText || ''
  const topBannerCtaText = data?.contentfulComponentConfigData?.topBannerCtaText || ''
  const topBannerCtaLink = data?.contentfulComponentConfigData?.topBannerCtaLink || ''

  const { lineItemsCount } = useStore()
  const { showToast } = useToast()

  const [searchInput, setSearchInput] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isSearchPopover, setIsSearchPopover] = useState(false)
  const searchPopoverRef = useRef(null)
  const headerRef = useRef(null)
  const searchInputRef = useRef(null)

  const [isSearchOpened, setIsSearchOpened] = useState(false)

  const handleOpenSearch = () => {
    setIsSearchOpened(true)
  }

  const handleCloseSearch = () => {
    setIsSearchOpened(false)
  }

  const removeBoldTags = (text) => text.replace(/<\/?b>/gi, '')

  const onSearch = useCallback(
    (manualSearch) => {
      if (searchInput.length < 2) {
        showToast('Validation error', 'Please, enter at least 3 characters')
        return
      }

      const params = new URLSearchParams(window.location.search)
      params.set('query', manualSearch || searchInput)
      navigate(`${ROUTES.SEARCH}?${params.toString()}`)
    },
    [searchInput]
  )

  const appWrapperContext = useAppWrapped()

  const currentSubPages = useMemo(() => {
    appWrapperContext?.setShowAdditionalMenu(false)
    if (pageContext.breadcrumb) {
      const isSurvey =
        surveyPages.some((page) => pageContext.breadcrumb.location.includes(page.node.pageSlug)) ||
        pageContext.breadcrumb.location.includes(ROUTES.SURVEY_TOP_LEVEL)
      if (isSurvey) {
        return {
          primary: surveyPages.map((page) => ({
            link: `${ROUTES.SURVEY_TOP_LEVEL}/${page.node.pageSlug}`,
            title: page.node.navigationTitle,
            enabled: pageContext.breadcrumb.location.includes(page.node.pageSlug),
          })),
        }
      }

      // check repair routes
      const isRepair =
        repairPages.some((page) => pageContext.breadcrumb.location.includes(page.node.pageSlug)) ||
        pageContext.breadcrumb.location.includes(ROUTES.REPAIRS)
      if (isRepair) {
        return {
          primary: repairPages.map((page) => ({
            link: `${ROUTES.REPAIRS}/${page.node.pageSlug}`,
            title: page.node.navigationTitle,
            enabled: pageContext.breadcrumb.location.includes(page.node.pageSlug),
          })),
        }
      }

      // check rental routes
      const isRental =
        rentalPages.some((page) =>
          pageContext.breadcrumb.location.includes(page.node?.pageSlug || page.linkDestination)
        ) || pageContext.breadcrumb.location.includes(ROUTES.RENTAL)
      if (isRental) {
        const altTitles = {
          Rental: 'View Rental Drones',
        }

        return {
          primary: rentalPages.map((page) => {
            const title = page.node?.navigationTitle || page.title
            return {
              link: page.node
                ? `${ROUTES.RENTAL}/${page.node?.pageSlug}`
                : `${ROUTES.DRONES}/${page?.linkDestination}`,
              title: altTitles[title] || title,
              enabled: pageContext.breadcrumb.location.includes(
                page.node?.pageSlug || page.linkDestination
              ),
            }
          }),
        }
      }

      // check training routes
      // const isTraining =
      //   trainingPages.some((page) =>
      //     pageContext.breadcrumb.location.includes(page.node.pageSlug)
      //   ) || pageContext.breadcrumb.location.includes(ROUTES.TRAINING_TOP_LEVEL)
      // if (isTraining) {
      //   return {
      //     primary: trainingPages.map((page) => ({
      //       link: `${ROUTES.TRAINING_TOP_LEVEL}/${page.node.pageSlug}`,
      //       title: page.node.navigationTitle,
      //       enabled: pageContext.breadcrumb.location.includes(page.node.pageSlug),
      //     })),
      //   }
      // }

      // const isDrones =
      //   dronesPages.some((page) =>
      //     pageContext.breadcrumb.location.includes(page.linkDestination)
      //   ) || pageContext.breadcrumb.location.includes(ROUTES.DRONES)
      // if (isDrones) {
      // USE IF SECONDARY DRONES MENU IS NEEDED
      // let secondary = []
      // const enterpriseLink = dronesPages.find(
      //   (page) => page.linkDestination === 'enterprise-collection'
      // )?.linkDestination

      // const isEnterprise =
      //   pageContext.breadcrumb.location.includes(enterpriseLink) ||
      //   enterprisePages.some((page) =>
      //     pageContext.breadcrumb.location.includes(page.linkDestination)
      //   )
      // if (isEnterprise) {
      //   secondary = enterprisePages.map((page) => ({
      //     link: `${ROUTES.DRONES}/${page.linkDestination}`,
      //     title: page.title,
      //     enabled: pageContext.breadcrumb.location.includes(page.linkDestination),
      //   }))
      //   appWrapperContext?.setShowAdditionalMenu(true)
      // }

      // const altTitles = {
      //   'Cameras, Payloads & Handheld Scanners': 'Payloads and Cameras',
      //   'DJI Mavic Enterprise': 'DJI Mavic 3 Enterprise',
      // }

      // return {
      //   primary: dronesPages.map((page) => ({
      //     link:
      //       page.title === 'All Drones'
      //         ? page.linkDestination
      //         : `${ROUTES.DRONES}/${page.linkDestination}`,
      //     title: altTitles[page.title] || page.title,
      //     enabled:
      //       page.title === 'All Drones'
      //         ? pageContext.breadcrumb.location === `${ROUTES.DRONES}/`
      //         : pageContext.breadcrumb.location.includes(page.linkDestination),
      // USE IF SECONDARY DRONES MENU IS NEEDED
      // ----------------------------------------
      // ||
      // (secondary.length && page.linkDestination.includes(enterpriseLink)),
      // -----------------------------------------
      // })),
      // ------------------------------------------
      // secondary,
      // ------------------------------------------
      // }
      // }
    }

    return { primary: [], secondary: [] }
  }, [
    pageContext.breadcrumb?.location,
    surveyPages,
    surveySubPages,
    repairPages,
    rentalPages,
    trainingPages,
  ])

  const fetchResults = async (input) => {
    if (input.length < 2) {
      return
    }

    try {
      const contentfulFetch = async (query) => {
        const response = await fetch(
          `https://cdn.contentful.com/spaces/et769tc4wc1v/environments/${process.env.CONTENTFUL_BRANCH ?? 'development'}/entries?access_token=ZGkLEpTUXImt2sV6Ti1OoTx596X5Oafre3NRrtGR9sw&query=${query}&limit=50`
        )
        return response.json()
      }

      const [search, predictiveSearch, contentful] = await Promise.all([
        searchClient.searchTitles(input, 50),
        searchClient.predictiveSearchTitles(input, 50),
        contentfulFetch(input),
      ])

      const allProducts = [
        ...search.edges,
        ...(predictiveSearch?.products.map((product) => ({ node: product })) || []),
      ]

      const uniqueProducts = allProducts.filter(
        (product, index, self) =>
          index === self.findIndex((p) => p.node.id === product.node.id) &&
          product.node.featuredImage &&
          !product.node.tags.includes('Hidden')
      )

      const filteredContentful = contentful.items.filter((item) =>
        [
          'pageHomapage',
          'pageCollections',
          'pageBlogArticle',
          'pageSupportGuide',
          'pageSubLevelTemplate',
          'pageTrainingTopLevel',
          'pageProductFamily',
          'pageSurveyTopLevel',
          'pageRichSubPage',
          'pageBasicPage',
          'pageIndustry',
          'pageIndustrySubLevel',
          'pageGsdCalculator',
          'pageTrainingCalculator',
          'pageJob',
          'pageRepairCalculator',
          'pageTermsAndConditions',
        ].includes(item.sys.contentType.sys.id)
      )

      const combinedResults = [
        ...uniqueProducts.map((product) => ({
          title: product.node.title,
          contentType: 'product',
          slug: product.node.handle,
        })),
        ...filteredContentful.map((item) => ({
          title:
            item.title ||
            item.fields.articleTitle ||
            item.fields.mastheadTitle ||
            item.fields.mastheadJobTitle ||
            item.fields.duration ||
            item.fields.department ||
            item.fields.mastheadTitleLine,
          contentType: item.sys.contentType.sys.id,
          slug: item.fields.pageSlug,
          parentCategory: item.fields.parentCategory || '',
        })),
      ]

      const exactResults = []
      const restResults = []

      combinedResults.forEach((result) => {
        if (result.title.toLowerCase().includes(input.toLowerCase())) {
          exactResults.push(result)
        } else {
          restResults.push(result)
        }
      })

      const sortedResults = [...exactResults, ...restResults]

      setSearchResults(sortedResults)
      setIsSearchPopover(true)
    } catch (error) {
      console.error('Error fetching search results:', error)
      showToast('Error', 'Failed to fetch search results')
    }
  }

  const debouncedFetchResults = useRef(debounce(fetchResults, 500)).current

  const handleInputChange = useCallback(
    (e) => {
      setSearchInput(e.target.value)
      debouncedFetchResults(e.target.value)

      if (e.target.value.length < 2) setIsSearchPopover(false)
    },
    [debouncedFetchResults]
  )

  const highlightMatches = (result, query) => {
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

    const escapedQuery = query.split(' ').map(escapeRegExp).join('|')
    const regex = new RegExp(`(${escapedQuery})`, 'gi')
    return result.replace(regex, (match) => `<b>${match}</b>`)
  }

  const appContext = useContext(AppContext)
  const isMobile = useIsMobile()

  const highlightedResults = useMemo(
    () =>
      searchResults.map((result) => ({
        ...result,
        title: highlightMatches(result.title, searchInput),
      })),
    [searchResults, searchInput]
  )

  useClickOutside(searchPopoverRef, () => setIsSearchPopover(false))
  useClickOutside(headerRef, () => appContext?.CloseAll())

  // add focus to the search field
  useEffect(() => {
    if (isSearchOpened && searchInputRef?.current) {
      searchInputRef?.current.focus()
    }
  }, [isSearchOpened])

  return (
    <AppContext.Consumer>
      {({
        globalNavActive,
        CloseAll,
        ToggleGlobalNav,
        isSubMenuActive,
        closeSubMenu,
        openSubMenu,
        closeFirstMenu,
      }) => (
        <div
          ref={headerRef}
          className={`GlobalNavWrapper ${
            hasScrolled ? '' : 'GlobalNavWrapper--notScrolled'
          } ${globalNavActive ? '' : 'GlobalNavWrapper--menuClosed'} ${isTopBanner && 'black-friday'}`}
        >
          <div className="NavigationWrapper">
            {isTopBanner && (
              <BlackFridayBanner
                bannerText={topBannerText}
                ctaText={topBannerCtaText}
                ctaLink={topBannerCtaLink}
              />
            )}
            <div className="outer">
              <div className={`inner ${isSearchOpened ? 'input-opened' : ''}`}>
                <Link className="LogoWrapper" to="/" aria-label="main page" />
                <div className="SearchInputWrapper">
                  <input
                    ref={searchInputRef}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') onSearch()
                    }}
                    placeholder="Search heliguy™"
                    value={searchInput}
                    onChange={handleInputChange}
                    className="SearchInput"
                    type="search"
                  />
                  <button onClick={() => onSearch()} className="SearchButton" type="submit" />
                  <button onClick={handleCloseSearch} className="CloseButton" />
                  {isSearchPopover && (
                    <div ref={searchPopoverRef} className="SearchPopover">
                      {highlightedResults.map((item) => (
                        <button
                          onClick={() => {
                            setIsSearchPopover(false)
                            setSearchInput('')
                            setIsSearchOpened(false)
                            navigate(
                              getSearchLink(item.contentType, item.slug, item.parentCategory)
                            )
                          }}
                          className="SearchPopoverItem"
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      ))}
                      <button
                        onClick={() => {
                          setIsSearchPopover(false)

                          onSearch(searchInput)
                        }}
                        className="SearchPopoverItem SearchPopoverBtn"
                      >
                        <span>Search for "{searchInput}"</span>
                        <span>
                          <img alt="arrow" src={ArrowIcon} />
                        </span>
                      </button>
                    </div>
                  )}
                </div>
                <ul className="SecondaryNavLinkList">
                  <li className="SecondaryNavLinkListItem">
                    <button
                      onClick={handleOpenSearch}
                      className="SearchButtonMobileToggle SearchButton"
                      aria-label="search"
                    >
                      <span className="FakeInput">Search</span>
                      <span className="NavigationButton--search"></span>
                    </button>
                  </li>
                  <li className="SecondaryNavLinkListItem">
                    <Link
                      to={ROUTES.CART}
                      className="NavigationButton NavigationButton--cart"
                      type="button"
                      aria-label="shopping cart"
                      onClick={CloseAll}
                    >
                      {lineItemsCount ? (
                        <span className="CartItemCount">{lineItemsCount}</span>
                      ) : (
                        ''
                      )}
                    </Link>
                  </li>
                  <li className="SecondaryNavLinkListItem">
                    <Link
                      to={ROUTES.ACCOUNT}
                      className="NavigationButton NavigationButton--login"
                      onClick={CloseAll}
                      aria-label="login"
                    />
                  </li>
                  <li className="SecondaryNavLinkListItem">
                    <a
                      href="https://training.heliguy.com/"
                      className="NavigationButton NavigationButton--school"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="training"
                    />
                  </li>
                  <li className="SecondaryNavLinkListItem">
                    <a
                      href={`tel:${phone}`}
                      className="NavigationButton NavigationButton--phone"
                      aria-label="contact phone"
                    />
                  </li>
                  <li className="SecondaryNavLinkListItem">
                    <a
                      href={`tel:${phone}`}
                      className="NavigationPhoneLink"
                      aria-label="contact phone"
                    >
                      {formatPhoneNumber(phone)}
                    </a>
                  </li>
                </ul>
                <div className="MobileMenuButtonWrapper">
                  <button
                    type="button"
                    className="MobileMenuButton"
                    onClick={() => ToggleGlobalNav(isSubMenu, isMegaMenu)}
                    aria-label="toggle menu"
                  />
                </div>
                <div className={`MainNavDrawer ${globalNavActive ? 'show' : 'hidden'}`}>
                  <ul className="MainNavLinkList">
                    <li className="MainNavLinkListItem">
                      <Link
                        to={ROUTES.SURVEY_TOP_LEVEL}
                        onClick={() => handleMenuItemClick(closeFirstMenu)}
                      >
                        Survey
                      </Link>
                    </li>
                    <li className="MainNavLinkListItem">
                      <Link to={ROUTES.RENTAL} onClick={() => handleMenuItemClick(closeFirstMenu)}>
                        Rental
                      </Link>
                    </li>
                    <li
                      className={`MainNavLinkListItem ${currentPage === ROUTES.TRAINING_TOP_LEVEL ? ' with-trigger' : ''}`}
                    >
                      <Link
                        to={ROUTES.TRAINING_TOP_LEVEL}
                        onClick={() => handleMenuItemClick(openSubMenu)}
                      >
                        Training
                      </Link>
                      {currentPage === ROUTES.TRAINING_TOP_LEVEL && (
                        <button
                          className={`mega-menu-trigger ${isMegaMenuOpen ? ' mega-menu-trigger-close' : ''}${isMobile ? ' mobile-trigger' : ''}`}
                          type="button"
                          onClick={handleMegaMenuTrigger}
                        >
                          {isMobile ? <AltCherevonIcon size={40} /> : <CheveronIcon size={16} />}
                        </button>
                      )}
                    </li>
                    <li className="MainNavLinkListItem">
                      <Link to={ROUTES.REPAIRS} onClick={() => handleMenuItemClick(closeFirstMenu)}>
                        Repairs
                      </Link>
                    </li>
                    <li
                      className={`MainNavLinkListItem ${currentPage === ROUTES.DRONES ? ' with-trigger' : ''}`}
                    >
                      <Link to={ROUTES.DRONES} onClick={() => handleMenuItemClick(openSubMenu)}>
                        Drones
                      </Link>
                      {currentPage === ROUTES.DRONES && (
                        <button
                          className={`mega-menu-trigger ${isMegaMenuOpen ? ' mega-menu-trigger-close' : ''}${isMobile ? ' mobile-trigger' : ''}`}
                          type="button"
                          onClick={handleMegaMenuTrigger}
                        >
                          {isMobile ? <AltCherevonIcon size={40} /> : <CheveronIcon size={16} />}
                        </button>
                      )}
                    </li>
                    <li className="MainNavLinkListItem">
                      <Link to={ROUTES.BLOG} onClick={closeSubMenu}>
                        Blog
                      </Link>
                    </li>
                    <li className="MainNavLinkListItem">
                      <Link to={ROUTES.EVENTS} onClick={closeSubMenu}>
                        Events
                      </Link>
                    </li>
                    <li className="MainNavLinkListItem">
                      <Link to={ROUTES.ABOUT} onClick={closeSubMenu}>
                        About Us
                      </Link>
                    </li>
                    <li className="MainNavLinkListItem">
                      <Link to={ROUTES.CONTACTS} onClick={closeSubMenu}>
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {isMobile ? (
            <div
              className={`MegaMenuWrapper ${isSubMenuActive && (!!currentSubPages?.primary?.length || (isMegaMenu && isMegaMenuOpen)) ? `show ${isMegaMenu ? '' : 'with-next-arrow'}` : 'hidden'}`}
            >
              {isMegaMenu ? (
                <MegaMenu
                  isOpen={isMegaMenuOpen}
                  onToggleMegaMenu={setIsMegaMenuOpen}
                  mobile
                  currentPage={currentPage}
                  isGlobalNavActive={globalNavActive}
                  onClickItem={CloseAll}
                />
              ) : (
                <div className="outer sub-menu-wrapper">
                  <div className="inner">
                    <div className="SubNavList">
                      <MobileSubMenu setSliderRef={setSliderRef}>
                        {!!currentSubPages?.primary?.length &&
                          currentSubPages?.primary?.map((target, index) => (
                            <div
                              className={`SubNavListItem ${target.enabled ? 'enabled' : ''}`}
                              key={index}
                            >
                              <Link to={target.link}>{target.title}</Link>
                            </div>
                          ))}
                      </MobileSubMenu>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`MegaMenuWrapper ${(currentSubPages?.primary?.length && !isMegaMenu) || (isMegaMenu && isMegaMenuOpen) ? 'show' : 'hidden'}`}
            >
              {isMegaMenu ? (
                <MegaMenu
                  isOpen={isMegaMenuOpen}
                  currentPage={currentPage}
                  onToggleMegaMenu={setIsMegaMenuOpen}
                />
              ) : (
                <div className="outer">
                  <div className="inner">
                    <ul className="SubNavList">
                      {!!currentSubPages?.primary?.length &&
                        currentSubPages?.primary?.map((target, index) => (
                          <li
                            className={`SubNavListItem ${target.enabled ? 'enabled' : ''}`}
                            key={index}
                          >
                            <Link to={target.link}>{target.title}</Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}

          <div
            className={`MegaMenuWrapper ${(isMobile ? globalNavActive && !!currentSubPages?.secondary?.length : !!currentSubPages?.secondary?.length) && appWrapperContext?.showAdditionalMenu ? 'show' : 'hidden'}`}
          >
            {/* ADD SECONDURY SUB MENU */}
            {/* <div className="outer">
              <div className="inner">
                <ul className="SubNavList">
                  {!!currentSubPages?.secondary?.length &&
                    currentSubPages?.secondary?.map((target, index) => (
                      <li
                        className={`SubNavListItem ${target.enabled ? 'enabled' : ''}`}
                        key={index}
                      >
                        <Link to={target.link} onClick={CloseAll}>
                          {target.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </AppContext.Consumer>
  )
}

export default GlobalNavigation

GlobalNavigation.propTypes = {}
