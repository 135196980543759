import React, {useCallback, useEffect, useState} from 'react';
import {CloseButton, Container, Wrapper} from "./RecommendationBlock.styles";
import TypographyAtom from "../../atoms/typography/typography";

import ButtonAtom from "../../atoms/buttons/buttons";
import {graphql, navigate, useStaticQuery} from "gatsby";

function RecommendationBlock() {
    const [industryTitle, setIndustryTitle] = useState(null);
    const [industryData, setIndustryData] = useState(null);
    const [opened, setOpened] = useState(false);

    const data = useStaticQuery(graphql`
    {    
        contentfulComponentConfigData {
            snitcherIndustries {
                id
                industry
                title
                messaging
                linkTitle
                linkHref
            }
        }
    }`)

    const recommendations = data?.contentfulComponentConfigData?.snitcherIndustries || [];

    useEffect(() => {
        const getUserCompany = (e) => {
            const company = e.detail?.user_company;
            const industry = company?.industry;
            setIndustryTitle(industry);
        }

        const company = window?.user_company;
        const industry = company?.industry;

        if (industry) {
            setIndustryTitle(industry);
        } else {
            window.addEventListener('user_company_init', getUserCompany)
        }

        return () => {
            window.removeEventListener('user_company_init', getUserCompany)
        }
    }, []);

    useEffect(() => {
        const industryContent = recommendations.find(recommendation => recommendation.industry === industryTitle);
        const clicked = localStorage.getItem('heliguy_recommendation_clicked');

        if (industryTitle && industryContent && clicked !== "true") {
           setIndustryData(industryContent);
           setOpened(true);
        }
    }, [industryTitle, recommendations]);

    const onClick = useCallback(() => {
        setOpened(false);
        localStorage.setItem('heliguy_recommendation_clicked', "true");
        navigate(industryData?.linkHref);
    }, [industryData]);

    const onClose = useCallback(() => {
        setOpened(false);
    }, []);

    return <Wrapper className={(opened && industryData) ? "opened" : ""}>
        <Container>
            <CloseButton onClick={onClose} />
            <TypographyAtom type={"H3"} weight={"Light"} copy={industryData?.title}/>
            <TypographyAtom type={"P"} weight={"Regular"} copy={industryData?.messaging}/>
            <ButtonAtom buttonType={"Primary Button"} buttonCopy={industryData?.linkTitle} onClick={onClick}/>
        </Container>
    </Wrapper>;
}

export default RecommendationBlock