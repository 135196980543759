import { isValidPhoneNumber } from 'libphonenumber-js'

const isPhoneValid = ({ phone, required }) => {
  if ((!phone || phone.length < 6) && !required) return true
  try {
    return isValidPhoneNumber(phone)
  } catch (error) {
    return false
  }
}

export default isPhoneValid
