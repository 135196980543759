import React from 'react'

function CheveronIcon({ size = 16 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 640 640"
      fill="currentColor"
    >
      <path d="M342.624 225.6l-22.624-22.624-181.024 181.024 45.248 45.248 135.776-135.744 135.776 135.744 45.248-45.248z" />
    </svg>
  )
}

export default CheveronIcon
