import React, { createContext, useState } from 'react'

export const AppContext = createContext()

export function AppProvider({ children }) {
  const [globalNavActive, setGlobalNavActive] = useState(false)
  const [isSubMenuActive, setIsSubMenuActive] = useState(false)
  const [pageHasScrolled, setpageHasScrolled] = useState(false)

  const ToggleGlobalNav = (isSubMenu, isMegaMenu) => {
    if (!isSubMenu) {
      setGlobalNavActive(!globalNavActive)

      return
    }

    if (isMegaMenu) {
      setGlobalNavActive(!globalNavActive)
      setIsSubMenuActive(!isSubMenuActive)
    } else {
      if (isSubMenuActive && !globalNavActive) {
        setGlobalNavActive(true)
      }

      if (isSubMenuActive && globalNavActive) {
        setGlobalNavActive(false)
        setIsSubMenuActive(false)
      }

      if (!isSubMenuActive && !globalNavActive) {
        setIsSubMenuActive(true)
      }
    }
  }

  const CloseAll = () => {
    setGlobalNavActive(false)
    setIsSubMenuActive(false)
  }

  const closeFirstMenu = () => {
    setGlobalNavActive(false)
    setIsSubMenuActive(true)
  }

  const openSubMenu = () => {
    setIsSubMenuActive(true)
  }

  const closeSubMenu = () => {
    setIsSubMenuActive(false)
  }

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        globalNavActive,
        isSubMenuActive,
        ToggleGlobalNav,
        pageHasScrolled,
        setpageHasScrolled,
        closeSubMenu,
        openSubMenu,
        closeFirstMenu,
        CloseAll,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
