import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import SkeletonElement from '../Skeleton/Skeleton'
import GoogleReviewCarousel from '../GalleryCarousel/GoogleReviewCarousel'

const Container = styled.div`
  padding: 0;
  width: 100%;

  margin-bottom: 40px;

  @media screen and (max-width: 965px) {
  }

  @media screen and (max-width: 780px) {
  }
`
const Section = styled.div`
  &.paddin-right-none {
    padding-right: 0;
  }
`

const ReviewsText = styled.p`
  font-size: 14px;
  font-style: italic;
  color: darkgray;
  margin: 0;
`

function TrustedReviewsBlock() {
  const [isLoading, setIsLoading] = React.useState(true)

  const data = useStaticQuery(graphql`
    query {
      contentfulComponentTrustedReviews {
        googleRating
        trustpilotRating
        googleLink
        trustpilotLink
        reviews {
          fullName
          dateOfReview
          rating
          comment {
            raw
          }
          type
          userPhoto {
            url
          }
        }
      }
    }
  `)

  const reviewsInfo = data?.contentfulComponentTrustedReviews || null
  const reviews = reviewsInfo?.reviews || []

  React.useEffect(() => {
    if (data) {
      setIsLoading(false)
    }
  }, [data])

  if (isLoading) return <SkeletonElement placeholder="Loading Trusted Reviews..." />

  if (!reviewsInfo || !reviews.length) return <></>

  return (
    <Section className="outer margin-80 paddin-right-none">
      <div className="inner">
        <Container>
          <GoogleReviewCarousel reviews={reviews} />
          <ReviewsText>
            *These reviews are from{' '}
            <a rel="noreferrer" target="_blank" href={reviewsInfo.googleLink}>
              Google Shopping ({reviewsInfo.googleRating})
            </a>{' '}
            and{' '}
            <a href={reviewsInfo.trustpilotLink} rel="noreferrer" target="_blank">
              Trustpilot ({reviewsInfo.trustpilotRating})
            </a>
          </ReviewsText>
        </Container>
      </div>
    </Section>
  )
}

export default TrustedReviewsBlock
