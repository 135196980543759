// Module Import
//---------------------------------
import React, { useState, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Field from '../../../atoms/fields/field'
import TypographyAtom from '../../../atoms/typography/typography'
import useFormValidation from '../../../../hooks/useFormValidation'
import { contactFormFooterSchema } from '../../../../utils/form/validationSchema'
import ValidationWrapper from '../../../atoms/fields/ValidationWrapper'
import { useToast } from '../../../../context/ToastContext'
import ButtonAtom from '../../../atoms/buttons/buttons'
import { BodyInner, BodyWrapper, FieldWrapper, Form } from './ContactFormFooter.styles'
import axios from 'axios'

// Component Export
//---------------------------------
export default function ContactFormFooter() {
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef(null)

  const { showToast } = useToast()
  const { validateValues, validateFieldOnChange, errors } = useFormValidation({
    schema: contactFormFooterSchema,
    formRef,
  })

  const messageSentConfirmation = 'You have successfully subscribed to our newsletter!'

  const handleFieldClick = () => setIsSent(false)

  const handleErrors = async ({ target: { name, value } }) => {
    await validateFieldOnChange({ name, value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const formData = new FormData(e.target)

    const formValues = {
      email: formData.get('email'),
    }

    const validatedData = await validateValues(formValues)

    if (!validatedData) {
      showToast('Validation error', '')
      setIsLoading(false)
      return
    }

    const response = await axios
      .post(`${process.env.GATSBY_NETLIFY_API_URL}/mail_subscribe`, formData)
      .catch((err) => {
        showToast('Unsuccessfully', err?.response?.data?.error || 'Failed to subscribe')
        return null
      })

    if (response) {
      setIsSent(true)
      e.target.reset()
    }

    setIsLoading(false)
  }

  return (
    <Form ref={formRef} id="contactFormFooter" onSubmit={onSubmit}>
      {isSent ? (
        <TypographyAtom weight="Bold" type="H4" copy={messageSentConfirmation} />
      ) : (
        <>
          <BodyWrapper>
            <TypographyAtom type="H5" copy="Get our latest news" weight="Bold" />
            <BodyInner>
              <FieldWrapper>
                <ValidationWrapper errorMessage={errors.email}>
                  <Field
                    labelText="Contact Email"
                    labelFor="contactEmail"
                    placeholderText="Contact Email"
                    fieldType="email"
                    fieldId="contactEmail"
                    fieldName="email"
                    onChange={handleErrors}
                    onClick={handleFieldClick}
                  />
                </ValidationWrapper>
              </FieldWrapper>
              <ButtonAtom
                buttonType="Primary Button"
                buttonCopy="Sign Up"
                form="contactFormFooter"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </BodyInner>
          </BodyWrapper>
        </>
      )}
    </Form>
  )
}

ContactFormFooter.propTypes = {}
