import React from 'react'
import styled from 'styled-components'
import TypographyAtom from '../../atoms/typography/typography'
import ButtonAtom from '../../atoms/buttons/buttons'
import { brandBlack } from '../../../assets/consts/color'
import ArrowIconBlack from '../../../assets/images/icon_arrowBlack.png'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 20px 14px 10px 14px;

  @media only screen and (min-width: 48em) {
    flex-direction: row;
    gap: 24px;
  }

  p {
    color: ${brandBlack};
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }

  a {
    text-decoration: none;
    border: none;
    color: ${brandBlack};
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: 15px;

    &:visited {
      color: ${brandBlack};
    }

    &:after {
      background-image: url(${ArrowIconBlack});
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
      content: '';
      height: 14px;
      width: 14px;
    }

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
`

function BlackFridayBanner({ bannerText, ctaText, ctaLink }) {
  return (
    <Container>
      <TypographyAtom type="P" weight="Bold" copy={bannerText} />
      <ButtonAtom to={`/${ctaLink}`} buttonType="Simple Button" buttonCopy={ctaText} />
    </Container>
  )
}

export default BlackFridayBanner
