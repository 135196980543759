import React from 'react'
import styled from 'styled-components'
import GoogleRatingStar from '../../../assets/images/icon_google_star.webp'
import TrustpilotRatingStar from '../../../assets/images/icon_trustpilot.svg'
import TypographyAtom from '../../atoms/typography/typography'
import GoogleLogo from '../../../assets/images/icon_google_shopping.webp'

const Container = styled.li`
  position: relative;
  width: 360px;
  height: 204px;
  flex-shrink: 0;
  margin: 0;
  padding: 12px 20px;
  /* border: 1px solid gray; */
  border-radius: 10px;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.1);

  user-select: none;

  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: 14px;
  }

  @media screen and (min-width: 48em) {
    width: 411px;
  }

  @media screen and (max-width: 470px) {
    width: 320px;
  }
`
const ReviewStarsBlock = styled.div`
  display: flex;
  gap: 4px;
  /* margin-bottom: 10px; */
`
const ReviewStarIcon = styled.img`
  width: 20px;
`

const ReviewUserBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const ProviderIcon = styled.img`
  width: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
`
const UserImageBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;

  background-color: #fb7347;
`
const UserInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p:first-of-type {
  }

  p:last-of-type {
    opacity: 0.6;
  }
`
const UserName = styled(TypographyAtom)``
const DateOfReview = styled(TypographyAtom)``
const ReviewUserImage = styled.div``
const ReviewUserImagePlaceholderWrapper = styled.div`
  p {
    color: white;
  }
`
const ReviewUserImagePlaceholder = styled(TypographyAtom)``
const CommentBlockWrapper = styled.div`
  overflow-y: auto;
  scrollbar-width: thin;

  p {
    margin: 0;
  }
`
const TopWrapper = styled.div`
  display: grid;
  gap: 12px;

  @media screen and (min-width: 48em) {
    display: flex;
    align-items: center;
  }
`

const CommentBlock = styled(TypographyAtom)``

const formatDate = (inputDate) => {
  const date = new Date(inputDate)

  // Format the date to "Month Day, Year"
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

function TrustedReviewItem({ comment, rating, userIcon, fullName, dateOfReview, type }) {
  const formattedDate = formatDate(dateOfReview)
  const userName = fullName.length > 15 ? `${fullName.substring(0, 15)}...` : fullName

  const isGoogle = type === 'Google'

  return (
    <Container>
      <ProviderIcon
        src={isGoogle ? GoogleLogo : TrustpilotRatingStar}
        alt="provider"
        loading="lazy"
      />
      <TopWrapper>
        <ReviewUserBlock>
          <UserImageBlock>
            {userIcon ? (
              <ReviewUserImage src={userIcon} alt="user" loading="lazy" />
            ) : (
              <ReviewUserImagePlaceholderWrapper>
                <ReviewUserImagePlaceholder
                  type="P"
                  weight="Regular"
                  copy={userName[0]?.toUpperCase() || '?'}
                />
              </ReviewUserImagePlaceholderWrapper>
            )}
          </UserImageBlock>
          <UserInfoBlock>
            <UserName type="P" weight="Bold" copy={userName} />
            <DateOfReview type="P" weight="Regular" copy={formattedDate} />
          </UserInfoBlock>
        </ReviewUserBlock>
        <ReviewStarsBlock>
          {new Array(rating).fill(null).map((_, index) => (
            <ReviewStarIcon
              key={index}
              src={isGoogle ? GoogleRatingStar : TrustpilotRatingStar}
              alt="star"
              loading="lazy"
            />
          ))}
        </ReviewStarsBlock>
      </TopWrapper>
      <CommentBlockWrapper>
        <CommentBlock type="BlockText" weight="Regular" copyBlock={comment} />
      </CommentBlockWrapper>
    </Container>
  )
}

export default TrustedReviewItem
