import styled from "styled-components";
import {brandIvory} from "../../../../assets/consts/color";

const Form = styled.form`
    display: flex;
    gap: 8px;

    @media only screen and (min-width: 82em) {
        background-color: ${brandIvory};
        padding: 12px 15px;
    }
    
    .field-error-message {
        display: none;
    }
`

const BodyWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    
    & > h5  {
        flex-shrink: 0;
        align-items: center;
        display: flex;
        width: 100%;
    }
    
    @media only screen and (min-width: 82em) {
        flex-direction: row;
        gap: 24px;
        & > h5  {
            width: auto;
        }
    }
`

const BodyInner = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    background-color: ${brandIvory};
    padding: 6px 8px;
    
    @media only screen and (max-width: 82em) {
        & > button {
            max-width: 104px;
            max-height: 40px;
            align-self: center;
            font-size: 14px;
            display: grid;
            place-content: center;
            
            &:after {
                display: none;
            }
        }
    }
    
    @media only screen and (min-width: 82em) {
        background-color: transparent;
        padding: 0;
        gap: 20px;
        
        & > button {
            max-width: 150px;
            max-height: 56px;
        }
    }
`

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    //height: 52px;    
    
    input {
        padding-left: 12px;
        font-size: 16px;
        border-radius: 0;
    }
    
    span {
        font-size: 14px !important;
    }

    @media only screen and (max-width: 82em) {
        input {
            padding: 10px;
        }
    }
`

export {
    Form,
    FieldWrapper,
    BodyWrapper,
    BodyInner
}