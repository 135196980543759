import { useState, useEffect } from 'react'

export default function useFormValidation({ schema, formRef }) {
  const [errors, setErrors] = useState({})
  const [isFirstValidation, setIsFirstValidation] = useState(true)

  const validateValues = async (formValues) => {
    setIsFirstValidation(false)

    return schema
      .validate(formValues, { abortEarly: false })
      .then((validated) => validated)
      .catch((error) => {
        const errorMap = error.inner.reduce((acc, currentError) => {
          acc[currentError.path] = currentError.message
          return acc
        }, {})
        setErrors(errorMap)
        return null
      })
  }

  const validateFieldOnChange = async ({ name, value, comparedValue, compareFieldName }) => {
    if (isFirstValidation) return
    await schema
      .validate(
        {
          [name]: value,
          ...(comparedValue && { [compareFieldName]: comparedValue }),
        },
        { abortEarly: false }
      )
      .then((validated) => {
        if (errors[name]) {
          setErrors((prev) => {
            const { [name]: _, ...rest } = prev
            return rest
          })
        }
        return validated
      })
      .catch((error) => {
        const currentError = error.inner.find(({ path }) => path === name)
        if (currentError) {
          setErrors({
            ...errors,
            [currentError.path]: currentError.message,
          })
        } else if (errors[name]) {
          setErrors((prev) => {
            const { [name]: _, ...rest } = prev
            return rest
          })
        }
      })
  }

  const resetErrors = () => setErrors({})

  useEffect(() => {
    if (formRef) {
      const firstValidationError = Object.keys(errors)[0]

      if (
        firstValidationError &&
        formRef &&
        Object.keys(formRef.current.elements).includes(firstValidationError)
      ) {
        formRef.current.elements[firstValidationError].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formRef, errors])

  return {
    validateValues,
    validateFieldOnChange,
    errors,
    resetErrors,
  }
}
