export default {
    consumerDrones: "Consumer Drones",
    proDrones: "Pro Drones",
    enterpriseDrones: "Enterprise Drones",
    payloads: "Cameras, Payloads, Handheld Scanners",
    training: "Training",
    rental: "Rental",
    software: "Software",
    djIDock:'DJI Dock',
    djIM30:'DJI M30 Series',
    djiM300_M350:'DJI M350/M300 RTK',
    djiMavicEnterprise:'DJI Mavic Enterprise',
    djiMatrice4:'DJI Matrice 4 Series',
}