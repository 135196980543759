import styled from "styled-components";
import {
    brandDarkGrey,
    brandWhite,
    brandIvory,
    brandOrange
} from "../../../../assets/consts/color";
import {primaryFont} from "../../../../assets/consts/fonts";
import {Link} from "gatsby-link";

const GlobalFooterWrapper = styled.footer`
    background-color: ${brandIvory};
    padding: 40px 0 20px;
    
    display: flex;
    flex-direction: column;
    gap: 20px;

    //@media only screen and (min-width: 48em) {
    //    padding: 40px 0 20px;
    //}
`

const GlobalFooterInner = styled.div`
    background-color: ${brandWhite};
    display: flex;
    flex-direction: column;
    position: relative;
    
    @media only screen and (min-width: 82em) {
        &:after {
            background-color: ${brandIvory};
            background-position: bottom right;
            background-size: cover;
            bottom: -45px;
            content: '';
            height: 90px;
            position: absolute;
            transform: rotate(135deg);
            right: -45px;
            width: 90px;
            z-index: 1;

            clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
        }
    }

    overflow: hidden;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
`

const SocialsBlock = styled.div`
    display: ${(props) => (props?.isMobile ? 'flex' : 'none')};
    flex-direction: column;
    gap: 25px;

    @media only screen and (min-width: 82em) {
        display: ${(props) => (props?.isMobile ? 'none' : 'flex')};
    }
`

const MiddleWrapper = styled.div`
    .inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 72px;
    padding-top: 36px;
        
    @media only screen and (min-width: 48em) {
      flex-direction: row;
      gap: 80px;

      padding-top: 72px;
    }
  }
`

const Categories = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    @media only screen and (min-width: 62em) {
        grid-template-columns: repeat(3, 1fr);
    }
    
    @media only screen and (min-width: 82em) {
        grid-template-columns: repeat(5, 1fr);
    }
`

const MiddleInner = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 20px;

    @media only screen and (min-width: 82em) {
        grid-template-columns: 25% 75%; 
    }
`

const ContactBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`

const BottomWrapper = styled.div`
    max-width: 1920px;
    margin: 0 auto;

  @media only screen and (min-width: 62em) {
      padding: 14px 0;
  }  
    
  .inner {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 48em) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`

// Logo
// ----------//
const LogoWrapper = styled.div`
  display: flex;

  img {
    max-width: 215px;
    max-height: 100px;
  }

    @media only screen and (min-width: 48em) {
        img {
            max-width: 270px;
        }
    }
`

const MiddleBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`

const FormBlock = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;

    form {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    
    @media only screen and (min-width: 62em) {
        grid-template-columns: repeat(3, 1fr);
        form {
            grid-column-start: 2;
            grid-column-end: 4;
        }
    }

    @media only screen and (min-width: 82em) {
        grid-template-columns: repeat(5, 1fr);
        form {
            grid-column-start: 3;
            grid-column-end: 6;
        }
    }
`



// Social List
// ----------//
const SocialLinks = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    a,
    span {
        display: flex;
        align-items: center;
        font-style: normal;
        color: ${brandDarkGrey};
        font-family: ${primaryFont};
        font-size: 14px;
        font-weight: 700;
        line-height: 100%; /* 16px */
        text-decoration: none;
    }

    @media only screen and (min-width: 48em) {
        grid-template-columns: 80px auto;
        gap: 25px;
    }
`

const SocialLinksList = styled.ul`
    display: flex;
    gap: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
`

const SocialLinksListItem = styled.li`
  margin: 0;
`

const SocialLink = styled.a`
  background-image: url(${({ icon }) => icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 24px;
  width: 24px;
  opacity: 1;

  &:hover {
    opacity: .8;
  }
`

const ContactLink = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    
    @media only screen and (min-width: 48em) {
        grid-template-columns: 80px auto;
        gap: 25px;
    }

    a,
    span {
        display: flex;
        align-items: center;
        font-style: normal;
        color: ${brandDarkGrey};
        font-family: ${primaryFont};
        font-size: 14px;
        font-weight: 400;
        line-height: 100%; /* 16px */
        text-decoration: none;
    }
    
    a:hover {
        color: ${brandOrange};
    }
`

// Nav List
// ----------//

const FooterNavListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`

const FooterNavList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &:before {
        content: "•";
        position: absolute;
        top: -5px;
        left: -10px;
    }
`

const FooterNavListItem = styled.li`
  //text-align: left !important;
  margin: 0;
    
  a {
      text-align: left;
  }  
`

const FooterNavListTitle = styled.li`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
  margin: 0 0 8px 0;

  a {
    text-align: left;
  }
`

// Bottom Nav List
// ----------//
const BottomNavListItemLink = styled(Link)`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: ${brandDarkGrey};
  }
`

const BottomNavList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 12px 24px;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;

  @media only screen and (min-width: 48em) {
    flex-direction: row;
  }
`

const BottomNavListItem = styled.li`
  //margin: 0 0 0 24px;
    margin: 0;
`

const BottomInner = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    width: 100%;

    @media only screen and (min-width: 62em) {
        grid-template-columns: repeat(3, 1fr);
    }
`

const AddressDetails = styled.span`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  //margin: 0 0 16px 0;
    text-align: center;

    @media only screen and (min-width: 62em) {
        text-align: start;
    }  
    
  @media only screen and (min-width: 48em) {
    margin: 0;
  }
`

// Publication
// ----------//
const PublicationDetails = styled.span`
  color: ${brandDarkGrey};
  font-family: ${primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  //margin: 0 0 16px 0;

  @media only screen and (min-width: 48em) {
    margin: 0;
  }
    text-align: center;
`

export {
    GlobalFooterWrapper,
    GlobalFooterInner,
    LogoWrapper,
    PublicationDetails,
    MiddleWrapper,
    BottomNavListItem,
    FormBlock,
    BottomNavListItemLink,
    BottomWrapper,
    FooterNavList,
    FooterNavListItem,
    FooterNavListTitle,
    FooterNavListWrapper,
    SocialLinksListItem,
    BottomNavList,
    ContactLink,
    SocialLink,
    SocialLinksList,
    Categories,
    MiddleInner,
    ContactBlock,
    SocialLinks,
    MiddleBlock,
    AddressDetails,
    BottomInner,
    SocialsBlock
}