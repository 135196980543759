import styled, { css } from 'styled-components'
import { brandDarkGrey, brandOrange } from '../../../../assets/consts/color'
import CloseIcon from '../../../atoms/icons/CloseIcon'

export const Wrapper = styled.div`
  width: 100%;
  ${({ globalNav, isTopBunner }) =>
    globalNav
      ? css`
          height: calc(100vh - ${isTopBunner ? '335px' : '255px'});
        `
      : css`
          height: calc(100vh - ${isTopBunner ? '200px' : '120px'});
        `}

  overflow: auto;
  padding-bottom: 40px;

  @media only screen and (min-width: 64em) {
    padding: 0 48px;
    height: auto;
  }
`
const styledLabel = css`
  cursor: pointer;
  color: ${brandDarkGrey};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  &:hover {
    color: ${brandOrange};
  }
`

export const MenuTrigger = styled.button`
  ${styledLabel}
  border: none;
  background-color: transparent;
`
export const Container = styled.div`
  position: relative;
  width: 100%;
`
export const ModalCloseIcon = styled(CloseIcon)`
  cursor: pointer;

  position: absolute;
  top: 0px;
  right: -16px;

  width: 32px;
  height: 32px;

  path {
    fill: ${brandDarkGrey};
  }
`

export const CategoryList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  column-gap: 8px;
  padding: 0;
  margin: 0;

  @media only screen and (min-width: 48em) {
    display: flex;
    align-items: top;
    justify-content: left;
    flex-wrap: wrap;
  }
`
export const CategoryItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  margin: 0;

  font-weight: 700;
  font-size: 16px;

  & > a {
    ${styledLabel}
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;

    &:visited {
      color: ${brandDarkGrey};
    }

    &:hover {
      color: ${brandOrange};
    }
  }

  @media only screen and (min-width: 48em) {
    padding: 32px;
  }
`
export const ProductList = styled.ul`
  display: grid;
  gap: 8px;
  margin: 0;
  padding: 0;

  li {
    margin: 0;

    a {
      ${styledLabel}
      text-decoration: none;
      font-size: 16px;

      &:visited {
        color: ${brandDarkGrey};
      }

      &:hover {
        color: ${brandOrange};
      }
    }
  }
`
