import styled from "styled-components";
import {brandDarkGrey, brandGrey, brandIvory, brandSoftGrey} from "../../../assets/consts/color";

export const Wrapper = styled.div`
    width: 90%;
    background: ${brandIvory};
    position: fixed;
    top: 50%;
    right: -100%;
    transform: translateY(-50%);
    transition: right 0.5s ease-in-out;
    
    z-index: 999999;
    
    border: 2px solid ${brandDarkGrey};
    
    &.opened { 
        right: 0;
    }

    @media only screen and (min-width: 48em) {
        width: 450px;
    }
`

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
`

export const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 0;
    transform: translate(-50%, -50%);
    background: #afadad;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    place-content: center;
    margin: 0 !important;
    outline: none;
    border: none;
    
    &:after {
        display: block;
        content:"";
        height: 12px;
        width: 2px;
        background: ${brandIvory};
        
        transform: translate(0px, -6px) rotate(45deg);
    }

    &:before {
        display: block;
        content:"";
        height: 12px;
        width: 2px;
        background: ${brandIvory};
        
        transform: translate(0px, 6px) rotate(-45deg);
    }
`