import variables from '../design_tokens/heliguy-2024-designTokens-transformed.json'

export const brandOrange = variables['Brand - Orange'].value
export const brandSoftOrange = variables['Brand - Orange - 30%'].value
export const brandGrey = variables['Brand - Grey'].value
export const brandSlate = variables['Brand - Slate'].value
export const brandKhaki = variables['Brand - Khaki'].value
export const brandWhite = variables['Brand - White'].value
export const brandIvory = variables['Brand - Ivory'].value
export const brandDarkGrey = variables['Brand - Dark Grey'].value
export const brandSoftGrey = variables['Brand - Soft Grey'].value
export const brandError = variables['Brand - Error'].value
export const brandSoftError = variables['Brand - Error - 30%'].value
export const brandBlack = variables['Brand - Black'].value
