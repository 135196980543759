export const ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ACTIVATE_ACCOUNT: '/activate-account',

  ACCOUNT: '/account',
  ACCOUNT_ORDER: '/account/order',

  CART: '/cart',
  CONTACTS: '/contacts',
  COLLECTIONS: '/collections',
  HOME: '/',
  PRODUCTS: '/products',
  SURVEY_TOP_LEVEL: '/heliguy-survey',
  TRAINING: '/drone-training',
  TRAINING_TOP_LEVEL: '/drone-training',
  INDUSTRY_TOP_LEVEL: '/industry',
  SERVICES: '/services',
  SUPPORT: '/support',
  BLOG: '/blogs',
  KNOWLEDGE_BASE: '/blogs/knowledge-base',
  ARTICLE_PREFIX: '/posts',
  ABOUT: '/about-heliguy',
  EVENTS: '/events',
  TRAINING_CALCULATOR: '/training-calculator',
  CAREERS: '/careers',

  RENTAL: '/drone-hire',
  REPAIRS: '/dji-drone-repair-centre',
  DRONES: '/dji-drones-shop',

  SEARCH: '/search',
  BLACK_FRIDAY: '/black-friday',
}

export const PARENT_PAGE_MAP = {
  TRAINING: 'Training',
  RENTAL: 'Rental',
  REPAIRS: 'Repair',
  SURVEY: 'Survey',
  DRONES: 'Drones',
}

export const SUB_PAGE_MAP = {
  [PARENT_PAGE_MAP.TRAINING]: ROUTES.TRAINING_TOP_LEVEL,
  [PARENT_PAGE_MAP.RENTAL]: ROUTES.RENTAL,
  [PARENT_PAGE_MAP.REPAIRS]: ROUTES.REPAIRS,
  [PARENT_PAGE_MAP.SURVEY]: ROUTES.SURVEY_TOP_LEVEL,
  [PARENT_PAGE_MAP.DRONES]: ROUTES.DRONES,
}
