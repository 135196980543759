import React from 'react'
import {
  CategoryItem,
  CategoryList,
  Container,
  ModalCloseIcon,
  ProductList,
  Wrapper,
} from './MegaMenu.styled'
import dronesMegaMenuArray from './menu-mockups/drones-mega-menu.json'
import trainingMegaMenuArray from './menu-mockups/trainig-mega-menu.json'
import { Link } from 'gatsby-link'
import { ROUTES } from '../../../../constants/routes'
import isOuterLink from '../../../../helpers/isOuterLink'

const megaMenuArray = {
  [ROUTES.DRONES]: dronesMegaMenuArray,
  [ROUTES.TRAINING_TOP_LEVEL]: trainingMegaMenuArray,
}

function MegaMenu({
  isOpen,
  onToggleMegaMenu,
  mobile,
  currentPage,
  isTopBunner,
  isGlobalNavActive,
  onClickItem,
}) {
  const handleTriggerClick = () => onToggleMegaMenu(!isOpen)

  const handleItemClick = () => {
    if (mobile || onClickItem) {
      onClickItem()
    }
    handleTriggerClick()
  }

  return (
    <Wrapper globalNav={isGlobalNavActive} isTopBunner={isTopBunner}>
      <div className="inner">
        <Container>
          {!mobile && <ModalCloseIcon onClick={handleTriggerClick} />}
          <CategoryList>
            {megaMenuArray[currentPage] &&
              megaMenuArray[currentPage].length > 0 &&
              megaMenuArray[currentPage].map(({ category, products }, index) => (
                <CategoryItem key={category.categoryLable || index} onClick={handleItemClick}>
                  {category.categorylink ? (
                    <Link to={category.categorylink}>{category.categoryLable}</Link>
                  ) : (
                    category.categoryLable
                  )}

                  {products.length > 0 && (
                    <ProductList>
                      {products.map(({ productLabel, productLink }, index) => (
                        <li key={productLabel || index} onClick={handleItemClick}>
                          {isOuterLink(productLink) ? (
                            <a href={productLink} target="_blank">
                              {productLabel}
                            </a>
                          ) : (
                            <Link to={productLink}>{productLabel}</Link>
                          )}
                        </li>
                      ))}
                    </ProductList>
                  )}
                </CategoryItem>
              ))}
          </CategoryList>
        </Container>
      </div>
    </Wrapper>
  )
}

export default MegaMenu
