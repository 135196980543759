// Module Import
//---------------------------------
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby-link'
import ArrowIconWhite from '../../../assets/images/icon_arrowWhite.png'
import ArrowIconOrange from '../../../assets/images/icon_arrowOrange.png'
import Loader from '../loader/Loader'
import ImageCut from '../../../assets/images/graphic_imageCut.svg'

// Style Tokens
//---------------------------------
import tokenData from '../../../assets/design_tokens/heliguy-2024-designTokens-transformed.json'
import {
  brandBlack,
  brandDarkGrey,
  brandError,
  brandIvory,
  brandOrange,
  brandSoftError,
  brandSoftOrange,
  brandWhite,
} from '../../../assets/consts/color'
import { primaryFont } from '../../../assets/consts/fonts'

// Button Box Model - Inactive
const button_boxModel_background__inactive = tokenData.Button.Primary.Inactive.Container.value.fill
const button_boxModel_paddingHorizontal__inactive =
  tokenData.Button.Primary.Inactive.Container.value.horizontalPadding
const button_boxModel_paddingVertical__inactive =
  tokenData.Button.Primary.Inactive.Container.value.verticalPadding
const button_boxModel__borderRadius__inactive =
  tokenData.Button.Primary.Inactive.Container.value.borderRadius
const button_boxModel_borderWidth__inactive =
  tokenData.Button.Primary.Inactive.Container.value.borderWidth
const button_boxModel_borderColor__inactive =
  tokenData.Button.Primary.Inactive.Container.value.borderColor
const button_boxModel_boxShadow__inactive = `${tokenData.Button.Primary.Inactive.Container.value.boxShadow.x}px ${tokenData.Button.Primary.Inactive.Container.value.boxShadow.y}px ${tokenData.Button.Primary.Inactive.Container.value.boxShadow.blur}px ${tokenData.Button.Primary.Inactive.Container.value.boxShadow.spread}px ${tokenData.Button.Primary.Inactive.Container.value.boxShadow.color}`

// Button Typography - Innactive
const button_copy_fontColour__inactive = tokenData.Button.Primary.Inactive.Copy.value.fill
const button_copy_fontFamily__inactive =
  tokenData.Button.Primary.Inactive.Copy.value.typography.fontFamily
const button_copy_fontSize__inactive =
  tokenData.Button.Primary.Inactive.Copy.value.typography.fontSize
const button_copy_fontWeight__inactive =
  tokenData.Button.Primary.Inactive.Copy.value.typography.fontWeight
const button_copy_letterSpacing__inactive =
  tokenData.Button.Primary.Inactive.Copy.value.typography.letterSpacing
const button_copy_lineHeight__inactive =
  tokenData.Button.Primary.Inactive.Copy.value.typography.lineHeight

// Button Box Model - Hover
const button_boxModel_background__hover = tokenData.Button.Primary.Hover.Container.value.fill
const button_boxModel_paddingHorizontal__hover =
  tokenData.Button.Primary.Hover.Container.value.horizontalPadding
const button_boxModel_paddingVertical__hover =
  tokenData.Button.Primary.Hover.Container.value.verticalPadding
const button_boxModel__borderRadius__hover =
  tokenData.Button.Primary.Hover.Container.value.borderRadius
const button_boxModel_borderWidth__hover =
  tokenData.Button.Primary.Hover.Container.value.borderWidth
const button_boxModel_borderColor__hover =
  tokenData.Button.Primary.Hover.Container.value.borderColor
const button_boxModel_boxShadow__hover = `${tokenData.Button.Primary.Hover.Container.value.boxShadow.x}px ${tokenData.Button.Primary.Hover.Container.value.boxShadow.y}px ${tokenData.Button.Primary.Hover.Container.value.boxShadow.blur}px ${tokenData.Button.Primary.Hover.Container.value.boxShadow.spread}px ${tokenData.Button.Primary.Hover.Container.value.boxShadow.color}`

// Button Typography - Hover
const button_copy_fontColour__hover = tokenData.Button.Primary.Hover.Copy.value.fill
const button_copy_fontFamily__hover =
  tokenData.Button.Primary.Hover.Copy.value.typography.fontFamily
const button_copy_fontSize__hover = tokenData.Button.Primary.Hover.Copy.value.typography.fontSize
const button_copy_fontWeight__hover =
  tokenData.Button.Primary.Hover.Copy.value.typography.fontWeight
const button_copy_letterSpacing__hover =
  tokenData.Button.Primary.Hover.Copy.value.typography.letterSpacing
const button_copy_lineHeight__hover =
  tokenData.Button.Primary.Hover.Copy.value.typography.lineHeight

// Button Box Model - Focus
const button_boxModel_background__focus = tokenData.Button.Primary.Focussed.Container.value.fill
const button_boxModel_paddingHorizontal__focus =
  tokenData.Button.Primary.Focussed.Container.value.horizontalPadding
const button_boxModel_paddingVertical__focus =
  tokenData.Button.Primary.Focussed.Container.value.verticalPadding
const button_boxModel__borderRadius__focus =
  tokenData.Button.Primary.Focussed.Container.value.borderRadius
const button_boxModel_borderWidth__focus =
  tokenData.Button.Primary.Focussed.Container.value.borderWidth
const button_boxModel_borderColor__focus =
  tokenData.Button.Primary.Focussed.Container.value.borderColor
const button_boxModel_boxShadow__focus = `${tokenData.Button.Primary.Focussed.Container.value.boxShadow.x}px ${tokenData.Button.Primary.Focussed.Container.value.boxShadow.y}px ${tokenData.Button.Primary.Focussed.Container.value.boxShadow.blur}px ${tokenData.Button.Primary.Focussed.Container.value.boxShadow.spread}px ${tokenData.Button.Primary.Focussed.Container.value.boxShadow.color}`

// Button Typography - Focus
const button_copy_fontColour__focus = tokenData.Button.Primary.Focussed.Copy.value.fill
const button_copy_fontFamily__focus =
  tokenData.Button.Primary.Focussed.Copy.value.typography.fontFamily
const button_copy_fontSize__focus = tokenData.Button.Primary.Focussed.Copy.value.typography.fontSize
const button_copy_fontWeight__focus =
  tokenData.Button.Primary.Focussed.Copy.value.typography.fontWeight
const button_copy_letterSpacing__focus =
  tokenData.Button.Primary.Focussed.Copy.value.typography.letterSpacing
const button_copy_lineHeight__focus =
  tokenData.Button.Primary.Focussed.Copy.value.typography.lineHeight

// Button Box Model - Active
const button_boxModel_background__active = tokenData.Button.Primary.Active.Container.value.fill
const button_boxModel_paddingHorizontal__active =
  tokenData.Button.Primary.Active.Container.value.horizontalPadding
const button_boxModel_paddingVertical__active =
  tokenData.Button.Primary.Active.Container.value.verticalPadding
const button_boxModel__borderRadius__active =
  tokenData.Button.Primary.Active.Container.value.borderRadius
const button_boxModel_borderWidth__active =
  tokenData.Button.Primary.Active.Container.value.borderWidth
const button_boxModel_borderColor__active =
  tokenData.Button.Primary.Active.Container.value.borderColor
const button_boxModel_boxShadow__active = `${tokenData.Button.Primary.Active.Container.value.boxShadow.x}px ${tokenData.Button.Primary.Active.Container.value.boxShadow.y}px ${tokenData.Button.Primary.Active.Container.value.boxShadow.blur}px ${tokenData.Button.Primary.Active.Container.value.boxShadow.spread}px ${tokenData.Button.Primary.Active.Container.value.boxShadow.color}`

// Button Typography - Active
const button_copy_fontColour__active = tokenData.Button.Primary.Active.Copy.value.fill
const button_copy_fontFamily__active =
  tokenData.Button.Primary.Active.Copy.value.typography.fontFamily
const button_copy_fontSize__active = tokenData.Button.Primary.Active.Copy.value.typography.fontSize
const button_copy_fontWeight__active =
  tokenData.Button.Primary.Active.Copy.value.typography.fontWeight
const button_copy_letterSpacing__active =
  tokenData.Button.Primary.Active.Copy.value.typography.letterSpacing
const button_copy_lineHeight__active =
  tokenData.Button.Primary.Active.Copy.value.typography.lineHeight

// Button Box Model - Disabled
const button_boxModel_background__disabled = tokenData.Button.Primary.Disabled.Container.value.fill
const button_boxModel_paddingHorizontal__disabled =
  tokenData.Button.Primary.Disabled.Container.value.horizontalPadding
const button_boxModel_paddingVertical__disabled =
  tokenData.Button.Primary.Disabled.Container.value.verticalPadding
const button_boxModel__borderRadius__disabled =
  tokenData.Button.Primary.Disabled.Container.value.borderRadius
const button_boxModel_borderWidth__disabled =
  tokenData.Button.Primary.Disabled.Container.value.borderWidth
const button_boxModel_borderColor__disabled =
  tokenData.Button.Primary.Disabled.Container.value.borderColor
const button_boxModel_boxShadow__disabled = `${tokenData.Button.Primary.Disabled.Container.value.boxShadow.x}px ${tokenData.Button.Primary.Disabled.Container.value.boxShadow.y}px ${tokenData.Button.Primary.Disabled.Container.value.boxShadow.blur}px ${tokenData.Button.Primary.Disabled.Container.value.boxShadow.spread}px ${tokenData.Button.Primary.Disabled.Container.value.boxShadow.color}`

// Button Typography - Disabled
const button_copy_fontColour__disabled = tokenData.Button.Primary.Disabled.Copy.value.fill
const button_copy_fontFamily__disabled =
  tokenData.Button.Primary.Disabled.Copy.value.typography.fontFamily
const button_copy_fontSize__disabled =
  tokenData.Button.Primary.Disabled.Copy.value.typography.fontSize
const button_copy_fontWeight__disabled =
  tokenData.Button.Primary.Disabled.Copy.value.typography.fontWeight
const button_copy_letterSpacing__disabled =
  tokenData.Button.Primary.Disabled.Copy.value.typography.letterSpacing
const button_copy_lineHeight__disabled =
  tokenData.Button.Primary.Disabled.Copy.value.typography.lineHeight

// Style Declarations
//---------------------------------

const StyledButton = css`
  background-color: ${button_boxModel_background__inactive};
  padding: ${button_boxModel_paddingHorizontal__inactive}px
    ${button_boxModel_paddingVertical__inactive}px;
  border-radius: ${button_boxModel__borderRadius__inactive}px;
  border: 1px solid transparent;
  box-shadow: ${button_boxModel_boxShadow__inactive};
  font-family: ${button_copy_fontFamily__inactive};
  font-size: 14px;
  font-weight: ${button_copy_fontWeight__inactive};
  color: ${button_copy_fontColour__inactive};
  letter-spacing: ${button_copy_letterSpacing__inactive}px;
  line-height: 1.5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 56px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  transition:
    background-color,
    color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  min-width: 130px;
  text-wrap: nowrap;

  ${({ loading }) =>
    !loading &&
    css`
      &:after {
        display: none;
        background-image: url(${ArrowIconWhite});
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        content: '';
        height: 24px;
        width: 24px;
        flex-shrink: 0;
      }
    `}

  &:focus {
    /* background-color: ${button_boxModel_background__focus}; */
    padding: ${button_boxModel_paddingHorizontal__focus}px
      ${button_boxModel_paddingVertical__focus}px;
    border-radius: ${button_boxModel__borderRadius__focus}px;
    border-width: ${button_boxModel_borderWidth__focus}px;
    border-color: ${button_boxModel_borderColor__focus};
    box-shadow: ${button_boxModel_boxShadow__focus};
    font-family: ${button_copy_fontFamily__focus};
    font-size: 14px;
    font-weight: ${button_copy_fontWeight__focus};
    color: ${button_copy_fontColour__focus};
    letter-spacing: ${button_copy_letterSpacing__focus}px;
    line-height: ${button_copy_lineHeight__focus}px;
  }

  &:hover {
    background-color: #fff;
    color: ${brandOrange};
    cursor: pointer;
    border: 1px solid ${brandOrange};
    &:after {
      background-image: url(${ArrowIconOrange});
      transform: rotate(90deg);
    }
  }

  &:active {
    background-color: ${button_boxModel_background__active};
    padding: ${button_boxModel_paddingHorizontal__active}px
      ${button_boxModel_paddingVertical__active}px;
    border-radius: ${button_boxModel__borderRadius__active}px;
    border-width: ${button_boxModel_borderWidth__active}px;
    border-color: ${button_boxModel_borderColor__active};
    box-shadow: ${button_boxModel_boxShadow__active};
    font-family: ${button_copy_fontFamily__active};
    font-size: 14px;
    font-weight: ${button_copy_fontWeight__active};
    color: ${button_copy_fontColour__active};
    letter-spacing: ${button_copy_letterSpacing__active}px;
    line-height: ${button_copy_lineHeight__active}px;
    &:after {
      background-image: url(${ArrowIconWhite});
      transform: rotate(0deg);
    }
  }

  &:disabled {
    background-color: ${button_boxModel_background__disabled};
    padding: ${button_boxModel_paddingHorizontal__disabled}px
      ${button_boxModel_paddingVertical__disabled}px;
    border-radius: ${button_boxModel__borderRadius__disabled}px;
    border-width: ${button_boxModel_borderWidth__disabled}px;
    border-color: ${button_boxModel_borderColor__disabled};
    box-shadow: ${button_boxModel_boxShadow__disabled};
    font-family: ${button_copy_fontFamily__disabled};
    font-size: 14px;
    font-weight: ${button_copy_fontWeight__disabled};
    color: ${button_copy_fontColour__disabled};
    letter-spacing: ${button_copy_letterSpacing__disabled}px;
    line-height: ${button_copy_lineHeight__disabled}px;
  }

  @media only screen and (min-width: 48em) {
    font-size: ${button_copy_fontSize__inactive}px;

    min-width: 150px;
    &:after {
      display: flex;
    }

    &:hover {
      font-size: ${button_copy_fontSize__hover}px;
    }

    &:focus {
      font-size: ${button_copy_fontSize__focus}px;
    }

    &:active {
      font-size: ${button_copy_fontSize__active}px;
    }

    &:disabled {
      font-size: ${button_copy_fontSize__disabled}px;
    }
  }
`

const Button = styled.button`
  ${StyledButton}
`
const ButtonWithoutArrow = styled(Button)`
  &::after {
    display: none;
  }
`
const DisabledButton = styled(ButtonWithoutArrow)`
  background-color: ${brandIvory};
  color: ${brandDarkGrey};

  &:disabled {
    color: ${brandDarkGrey};
    background-color: ${brandIvory};
    cursor: auto;
  }
`

const ContainedButtonLink = styled(Link)`
  ${StyledButton}
  display: inline-flex;
  text-decoration: none;

  &:visited {
    color: ${brandWhite};

    &:hover {
      color: ${brandOrange};
    }
  }
`
const ContainedButtonOuterLink = styled.a`
  ${StyledButton}
  display: inline-flex;
  text-decoration: none;

  &:visited {
    color: ${brandWhite};
    background-color: ${brandOrange};
  }

  &:focus {
    color: ${brandWhite};
    background-color: ${brandOrange};
    border: 1px solid ${brandOrange};
    outline: none;
  }

  &:hover {
    color: ${brandOrange};
    background-color: ${brandWhite};
    border-color: ${brandOrange};
  }

  &:active {
    color: ${brandOrange};
    background-color: ${brandSoftOrange};
    border: 1px solid transparent;
    &:after {
      background-image: url(${ArrowIconOrange});
      transform: rotate(90deg);
    }
  }

  /* &::after {
    display: none;
  } */
`
// ---------- Cut Button ----------//
const CutButton = styled.button`
  align-items: center;
  background: ${brandOrange};
  border: 0;
  box-shadow: 0px 0px 0px 0px #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: 'F37 Bolton';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: 120%; /* 24px */
  padding: 40px;
  position: relative;
  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:after {
    content: '';
    background-color: white;
    bottom: -7px;
    height: 24px;
    position: absolute;
    right: -15px;
    transform: rotate(-45deg);
    width: 40px;
    z-index: 1;
  }

  &:hover {
    background: ${brandSoftOrange};
    color: ${brandOrange};
  }
`

// ---------- Cut Button ----------//
const SimpleButton = styled(Link)`
  display: flex;
  position: relative;
  padding: 16px;
  //flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  border: 2px solid transparent;
  box-shadow: 0px 0px 0px 0px ${brandBlack};
  color: ${({ buttonColor }) => (buttonColor === 'whiteButton' ? 'white' : brandOrange)};
  text-align: center;
  border-color: ${({ buttonColor }) => (buttonColor === 'whiteButton' ? 'white' : brandOrange)};
  background-color: transparent;
  cursor: pointer;
  height: 56px;

  /* typography/H5/Bold */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */

  min-width: 130px;

  ${({ loading }) =>
    !loading &&
    css`
      &:after {
        display: none;
        background-image: url(${ArrowIconOrange});
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        content: '';
        height: 24px;
        width: 24px;
        flex-shrink: 0;
        transform: rotate(90deg);
      }
    `}

  &:hover {
    background: ${brandSoftOrange};
    color: ${brandOrange};
    cursor: pointer;
    border-color: transparent;
  }

  @media only screen and (min-width: 48em) {
    font-size: 16px;
    min-width: 150px;
    &:after {
      display: flex;
    }
  }
`
const SecondaryButton = styled(SimpleButton)`
  border-width: 1px;
`

const SimpleButtonWithoutArrow = styled(SecondaryButton)`
  &:after {
    display: none !important;
  }
`

const DestructiveButton = styled(SimpleButton)`
  color: ${brandError};
  border-color: ${brandError};

  &:hover {
    border-color: transparent;
    background: ${brandSoftError};
    color: ${brandError};
  }
`

const CallModalButton = styled(Button)`
  padding: 40px;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  justify-content: center;

  &::after {
    display: none;
  }

  &:hover {
    font-size: 20px;
  }

  &:focus {
    padding: 40px;
    width: 100%;
    font-weight: 700;
    font-size: 20px;

    &:hover {
      background: ${brandSoftOrange};
      color: ${brandOrange};
      cursor: pointer;
    }
  }
`

const SubmitButton = styled.button`
  border: 0.001px solid ${brandOrange};
  border-bottom-left-radius: 4px;
  background: ${brandOrange};
  box-shadow: 0px 0px 0px 0px ${brandBlack};
  cursor: pointer;

  position: relative;
  width: 100%;
  display: flex;
  padding: 32px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: ${brandWhite};
  text-align: center;

  /* typography/body/bold */
  font-family: ${primaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */

  z-index: 1;
  transition:
    background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:hover {
    border-color: transparent;
    background: ${brandSoftOrange};
    color: ${brandOrange};
  }

  &:disabled {
    cursor: not-allowed;
    user-select: none;
    touch-action: none;
    pointer-events: none;
    opacity: 0.5;
  }

  &:after {
    content: '';
    background-color: white;
    bottom: -11px;
    height: 24px;
    position: absolute;
    right: -30px;
    transform: rotate(-45deg);
    width: 54px;
    z-index: 9999;
  }
`

const SubmitArrowButton = styled(SubmitButton)`
  padding: 16px;
  justify-content: space-between;
  flex-direction: row;

  font-weight: 400;
  font-size: 16px;

  border-radius: 0;

  &:after {
    position: static;
    flex-shrink: 0;
    background-color: transparent;
    background-image: url(${ArrowIconWhite});
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    height: 24px;
    width: 24px;
    transform: rotate(0deg);
  }

  &:hover {
    &:after {
      position: static;
      background-image: url(${ArrowIconOrange});
      transform: rotate(90deg);
    }
  }
`

const OutlinedButton = styled(SubmitButton)`
  border: 2px solid ${brandOrange};
  background-color: transparent;

  color: ${brandOrange};

  &:hover {
    border-color: transparent;
    background: ${brandSoftOrange};
    color: ${brandOrange};
  }
`

// Component Export
//---------------------------------
export default function ButtonAtom({
  buttonType,
  buttonCopy,
  isLoading,
  onClick = () => {},
  buttonColor,
  fullWidth,
  ...rest
}) {
  return (
    <>
      {buttonType === 'Primary Button' && (
        <Button loading={isLoading} onClick={onClick} fullWidth={fullWidth} {...rest}>
          {buttonCopy}
          {isLoading && <Loader />}
        </Button>
      )}
      {buttonType === 'Primary Button Without Arrow' && (
        <ButtonWithoutArrow loading={isLoading} onClick={onClick} fullWidth={fullWidth} {...rest}>
          {buttonCopy}
          {isLoading && <Loader />}
        </ButtonWithoutArrow>
      )}
      {buttonType === 'Disabled Button' && (
        <DisabledButton loading={isLoading} onClick={onClick} fullWidth={fullWidth} {...rest}>
          {buttonCopy}
          {isLoading && <Loader />}
        </DisabledButton>
      )}
      {buttonType === 'Simple Button' && (
        <SimpleButton buttonColor={buttonColor} {...rest}>
          {buttonCopy}
        </SimpleButton>
      )}
      {buttonType === 'Contained Button Link' && (
        <ContainedButtonLink loading={isLoading} {...rest}>
          {buttonCopy}
        </ContainedButtonLink>
      )}
      {buttonType === 'Contained Button Outer Link' && (
        <ContainedButtonOuterLink loading={isLoading} {...rest}>
          {buttonCopy}
        </ContainedButtonOuterLink>
      )}
      {buttonType === 'Secondary Button' && (
        <SecondaryButton
          as="button"
          buttonColor={buttonColor}
          fullWidth={fullWidth}
          onClick={onClick}
          {...rest}
        >
          {isLoading ? <Loader size="30px" /> : buttonCopy}
        </SecondaryButton>
      )}
      {buttonType === 'Secondary Button Without Arrow' && (
        <SimpleButtonWithoutArrow
          as="button"
          buttonColor={buttonColor}
          fullWidth={fullWidth}
          onClick={onClick}
          {...rest}
        >
          {isLoading ? <Loader size="30px" /> : buttonCopy}
        </SimpleButtonWithoutArrow>
      )}
      {buttonType === 'Destructive Button' && <DestructiveButton>{buttonCopy}</DestructiveButton>}
      {buttonType === 'Call Modal Button' && (
        <CallModalButton onClick={onClick} {...rest}>
          {buttonCopy}
        </CallModalButton>
      )}
      {buttonType === 'Submit Button' && (
        <SubmitButton onClick={onClick} {...rest}>
          {isLoading ? <Loader size="30px" /> : buttonCopy}
        </SubmitButton>
      )}
      {buttonType === 'Submit Arrow Button' && (
        <SubmitArrowButton onClick={onClick} {...rest}>
          {isLoading ? <Loader /> : buttonCopy}
        </SubmitArrowButton>
      )}
      {buttonType === 'Outlined Button' && (
        <OutlinedButton onClick={onClick} {...rest}>
          {isLoading ? <Loader size="30px" /> : buttonCopy}
        </OutlinedButton>
      )}
    </>
  )
}

ButtonAtom.propTypes = {
  buttonType: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
